import React, { useContext, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { animationDuration, ViewContext } from "../../context";

const ButtonOpen = styled.svg<{ active: boolean; state: boolean }>`
  ${tw`
    h-2.5
    bg-transparent
  `}

  > line {
    :first-child {
      stroke-dasharray: 32;
    }
    :last-child {
      stroke-dasharray: 24;
    }

    stroke-dashoffset: ${props => {
      if (!props.active && !props.state) {
        return 18;
      }
      if (props.active && !props.state) {
        return 0;
      }
      return 0;
    }};

    ${props => {
      if (!props.active && props.state) {
        return `animation: ${animationDuration}s ease-in normal menuButtonAnimation`;
      }
      if (props.active && props.state) {
        return `animation: ${animationDuration}s ease-in reverse menuButtonAnimation`;
      }
      return "";
    }};
  }
`;

const ButtonClose = styled.svg<{ active: boolean }>`
  ${tw`
    h-3
    left-[calc(1rem + 0.125rem)]
  `}
  transform: ${props => (props.active ? "scale(1)" : "scale(0)")};
`;

const Content = styled.div<{ active: boolean }>`
  ${tw`
    relative w-8 h-8 mr-2
    flex flex-col items-center justify-center
    cursor-pointer
  `}
  svg {
    ${tw`
      z-10
      absolute stroke-current
    `}
    transition: all 0.3s ease;
  }
`;

type MenuButtonState = {
  active: boolean;
};

const MenuButton = () => {
  const context = useContext(ViewContext);
  const [state, setState] = useState<MenuButtonState>({ active: false });

  const MenuButtonHandler = () => {
    setState({ active: true });

    if (!context.isMenuOpen) {
      context.setMenuState(true);
    } else {
      context.setMenuState(false);
    }
  };

  return (
    <Content onClick={() => MenuButtonHandler()} active={context.isMenuOpen}>
      <ButtonOpen
        active={!context.isMenuOpen}
        state={state.active}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 10"
        xmlSpace="preserve"
      >
        <line
          x1="0"
          y1="1"
          x2="32"
          y2="1"
          strokeLinecap="square"
          stroke="white"
          shapeRendering="crispEdges"
          strokeWidth={2}
          onAnimationEnd={() => setState({ active: false })}
        />
        <line
          x1="8"
          y1="9"
          x2="32"
          y2="9"
          strokeLinecap="square"
          stroke="white"
          shapeRendering="crispEdges"
          strokeWidth={2}
          onAnimationEnd={() => setState({ active: false })}
        />
      </ButtonOpen>
      <ButtonClose active={context.isMenuOpen} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" xmlSpace="preserve">
        <line x1="2" y1="2" x2="8" y2="8" strokeLinecap="square" stroke="white" shapeRendering="crispEdges" strokeWidth={2} />
        <line x1="2" y1="8" x2="8" y2="2" strokeLinecap="square" stroke="white" shapeRendering="crispEdges" strokeWidth={2} />
      </ButtonClose>
    </Content>
  );
};

export default React.memo(MenuButton);
