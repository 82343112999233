import React from "react";
import videojs, { VideoJsPlayer } from "video.js";
import "video.js/dist/video-js.css";
import PropTypes from "prop-types";
import "./VideoPlayer.scss";

const initialOptions: videojs.PlayerOptions = {
  autoplay: false,
  controls: true,
  responsive: true,
  aspectRatio: "16:9",
  poster: "/images/ReelPoster.png",
  preload: "auto",
  sources: [
    {
      src: "https://jst.design/video",
      type: "video/mp4",
    },
  ],
  controlBar: {
    children: ["playToggle", "progressControl", "volumePanel", "fullscreenToggle"],
  },
};

type VideoPlayerProps = {
  isPlayable?: boolean;
  options?: videojs.PlayerOptions;
};
const VideoPlayerPropTypes = {
  isPlayable: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.any,
};

const VideoPlayer = ({ isPlayable, options }: VideoPlayerProps) => {
  const videoRef: React.RefObject<HTMLVideoElement> = React.useRef(null);
  const playerRef: React.MutableRefObject<VideoJsPlayer | null> = React.useRef(null);

  React.useEffect(() => {
    if (!isPlayable) {
      if (videoRef.current) {
        videoRef.current.pause();
      }
    }
  }, [isPlayable]);

  React.useEffect(() => {
    // make sure VideoPlayer.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      playerRef.current = videojs(videoElement, videojs.mergeOptions(initialOptions, options));
    }
  }, [options]);

  // Dispose the VideoPlayer.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  // eslint-disable-next-line jsx-a11y/media-has-caption
  return <video ref={videoRef} className="video-js vjs-show-big-play-button-on-pause vjs-big-play-centered" />;
};

VideoPlayer.propTypes = VideoPlayerPropTypes;
VideoPlayer.defaultProps = {
  isPlayable: false,
  options: undefined,
};

export default React.memo(VideoPlayer);
