import React, { useContext, useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import tw from "twin.macro";
import { animationDuration, MenuItems, ScrollState, ViewContext, ViewIndex } from "../../context";
import Header from "../Header";
import Footer from "../Footer";
import Navigator from "../Navigator";
import Home from "../Home";
import About from "../About";
import Showreel from "../Showreel";
import Skills from "../Skills";
import Services from "../Services";
import Contact from "../Contact";
import MenuMobile from "../MenuMobile/MenuMobile";
import { TextBlock } from "../../styles";

const SideBar = styled.div`
  ${tw`
    w-4 h-full
  `}
`;

const LeftSideBar = styled(SideBar)`
  ${tw`
    
  `}
`;

const RightSideBar = styled(SideBar)`
  ${tw`
    
  `}
`;

const Inner = styled.div`
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    > *:not(:last-child) {
      ${tw`mb-4`}
    }
  }

  ${tw`
    w-full h-full relative
    laptop:px-8 desktop:px-16
    py-6
    flex flex-col items-start justify-around gap-y-4
  `}
`;

const getAnimationString = (duration: number, fade: "in" | "out", animation: "from-top" | "from-bottom") => {
  return `
  animation: ${duration}s forwards ${fade === "in" ? "reverse" : ""} ease-in backDropFade, 
  ${duration}s forwards ${fade === "in" ? "reverse" : ""} ease-in 
  ${animation === "from-bottom" ? "backDropFromBottomAnimation" : "backDropFromTopAnimation"}
  `;
};

const backDropAnimation = (fade: "in" | "out" | null, animation: "from-top" | "from-bottom" | null) => {
  if (fade !== null && animation !== null) {
    return getAnimationString(animationDuration, fade, animation);
  }
  return undefined;
};

const BackDropTitle = styled(TextBlock)<{ animation: "from-top" | "from-bottom" | null; fade: "in" | "out" | null; isMenuOpen: boolean }>`
  ${tw`
    h-full absolute
    text-left tracking-[0.25em] text-8xl leading-tight font-bold
    opacity-5
    select-none
  `}
  transform: translate3d(-4rem, 4rem, 0);
  writing-mode: vertical-lr;

  ${props => backDropAnimation(props.fade, props.animation)}
`;

const MainContent = styled.div<{
  isMenuOpen: boolean;
}>`
  ${tw`
    relative w-full h-[calc(100% - 8rem)] left-0
  `}

  ${props => (props.isMenuOpen ? tw`opacity-0 laptop:opacity-100 laptop:left-[-30vw]` : tw`opacity-100`)};
  transition: left ${animationDuration}s ease-in,
    ${props => (props.isMenuOpen ? `opacity ${animationDuration}s ease-in` : `opacity ${animationDuration}s ${animationDuration}s ease-in`)};

  > * {
    ${tw`
      absolute left-0 top-0
    `}
  }
`;

const Outer = styled.div`
  ${tw`
    w-full h-full relative
    px-4 mx-auto
    flex flex-row items-start justify-between
  `}
`;

const GlobalStyle = createGlobalStyle<{ currentView: ViewIndex }>`
  html, body {
    overscroll-behavior-y: ${props => (props.currentView === MenuItems.Home ? null : "contain")};
  }
`;

type ScrollingState = {
  deltaScroll: number;
  prevClientY: number;
  scrollScale: number;
  scrollSign: number;
};

type BackDropState = {
  isMenuActive: boolean;
  animation: "from-top" | "from-bottom" | null;
  fade: "in" | "out" | null;
};

const getViewByName = (viewName: ViewIndex | null) => {
  switch (viewName) {
    case MenuItems.Home:
      return <Home />;
    case MenuItems.About:
      return <About />;
    case MenuItems.Showreel:
      return <Showreel />;
    case MenuItems.Skills:
      return <Skills />;
    case MenuItems.Services:
      return <Services />;
    case MenuItems.Contact:
      return <Contact />;
    case null:
      return null;
    default:
      return <Home />;
  }
};

const Wrapper = () => {
  const context = useContext(ViewContext);

  const [scrollingState, setScrollingState] = useState<ScrollingState>({
    deltaScroll: 0,
    prevClientY: 0,
    scrollScale: 1,
    scrollSign: 1,
  });
  const [backDropState, setBackDropState] = useState<BackDropState>({ isMenuActive: false, animation: null, fade: null });

  const changeView = (view: number) => {
    const { currentView } = context;
    const viewCount = Object.keys(MenuItems).length / 2 - 1;

    if ((view === 1 && currentView !== viewCount) || (view === -1 && currentView !== 1)) {
      context.setNextView(currentView + view);
    }
  };

  const scrollHandler = (event: React.WheelEvent<HTMLDivElement>) => {
    const { currentView } = context;
    const { deltaScroll } = scrollingState;
    const { deltaY } = event;
    const newDelta = deltaScroll + deltaY;
    const viewCount = Object.keys(MenuItems).length / 2 - 1;
    const scrollState =
      context.scrollState === ScrollState.NONE ||
      (context.scrollState === ScrollState.DOWN && Math.sign(deltaY) === 1) ||
      (context.scrollState === ScrollState.UP && Math.sign(deltaY) === -1);

    if (currentView >= 1 && currentView <= viewCount && context.nextView === null && !context.isMenuOpen && scrollState) {
      if ((deltaScroll > 0 && newDelta <= deltaScroll) || (deltaScroll < 0 && newDelta >= deltaScroll)) {
        setScrollingState(prevState => ({ ...prevState, ...{ deltaScroll: deltaY } }));
      } else if (newDelta >= 300 || newDelta <= -300) {
        changeView(Math.sign(scrollingState.deltaScroll));
        setScrollingState(prevState => ({ ...prevState, ...{ deltaScroll: 0 } }));
      } else {
        setScrollingState(prevState => ({ ...prevState, ...{ deltaScroll: newDelta } }));
      }
    } else {
      setScrollingState(prevState => ({ ...prevState, ...{ deltaScroll: 0 } }));
    }
  };

  const touchStartHandler = (event: React.TouchEvent<HTMLDivElement>) => {
    const { clientY } = event.changedTouches[0];

    setScrollingState(prevState => ({ ...prevState, ...{ prevClientY: clientY } }));
  };

  const touchMoveHandler = (event: React.TouchEvent<HTMLDivElement>) => {
    const { prevClientY } = scrollingState;
    const { clientY } = event.changedTouches[0];
    const deltaY = prevClientY - clientY;
    const bodyHeight = window.innerHeight || document.documentElement.clientHeight;
    const moveLength = (deltaY / bodyHeight) * 100;

    if (context.scrollState === ScrollState.BETWEEN) {
      setScrollingState(prevState => ({
        ...prevState,
        ...{ prevClientY: clientY },
      }));
    }

    const isVideoActive = document.activeElement?.classList.value.includes("vjs");

    if (Math.abs(moveLength) < 20 && !isVideoActive && !context.isMenuOpen && context.nextView === null) {
      setScrollingState(prevState => ({
        ...prevState,
        ...{ scrollScale: 1 + Math.abs(moveLength) / 100 / 8, scrollSign: Math.sign(moveLength) },
      }));
    }
  };

  useEffect(() => {
    if (context.currentView === context.previousView) {
      setScrollingState(prevState => ({ ...prevState, ...{ scrollScale: 1 } }));
    }
  }, [context.currentView, context.previousView]);

  const touchEndHandler = (event: React.TouchEvent<HTMLDivElement>) => {
    const { currentView } = context;
    const { prevClientY } = scrollingState;
    const { clientY } = event.changedTouches[0];
    const viewCount = Object.keys(MenuItems).length / 2 - 1;
    const deltaY = prevClientY - clientY;
    const bodyHeight = window.innerHeight || document.documentElement.clientHeight;
    const moveLength = (deltaY / bodyHeight) * 100;

    if (context.scrollState === ScrollState.BETWEEN) {
      setScrollingState(prevState => ({
        ...prevState,
        ...{ prevClientY: clientY },
      }));
    }

    const isVideoActive = document.activeElement?.classList.value.includes("vjs");

    if (currentView >= 1 && currentView <= viewCount && !isVideoActive && !context.isMenuOpen && context.nextView === null) {
      if (Math.abs(moveLength) >= 15) {
        changeView(Math.sign(moveLength));
      } else {
        setScrollingState(prevState => ({ ...prevState, ...{ scrollScale: 1 } }));
      }
    }
  };

  useEffect(() => {
    if (context.currentView === context.nextView) {
      setScrollingState(prevState => ({ ...prevState, ...{ scrollScale: 1 } }));
    }
  }, [context.currentView, context.nextView]);

  useEffect(() => {
    document.title = `${MenuItems[context.currentView]} - jst.design`;
  }, [context.currentView]);

  useEffect(() => {
    if (context.nextView !== null) {
      if (context.currentView !== context.nextView) {
        // исчезает, контент уезжает вверх
        if (context.currentView < context.nextView) {
          return setBackDropState(prevState => ({ ...prevState, ...{ fade: "out", animation: "from-bottom" } }));
        }
        if (context.currentView > context.nextView) {
          return setBackDropState(prevState => ({ ...prevState, ...{ fade: "out", animation: "from-top" } }));
        }
      }
      if (context.currentView === context.nextView) {
        // появляется, контент приезжает сверху
        if (context.currentView > context.previousView) {
          return setBackDropState(prevState => ({ ...prevState, ...{ fade: "in", animation: "from-top" } }));
        }
        if (context.currentView < context.previousView) {
          return setBackDropState(prevState => ({ ...prevState, ...{ fade: "in", animation: "from-bottom" } }));
        }
      }
    }
    return () => {};
  }, [context.currentView, context.nextView, context.previousView]);

  const onBackDropAnimationEnd = () => {
    setBackDropState(prevState => ({ ...prevState, ...{ isMenuActive: !prevState.isMenuActive, fade: null, position: null } }));
  };

  return (
    <Outer
      onWheel={event => scrollHandler(event)}
      onTouchStart={event => touchStartHandler(event)}
      onTouchMove={event => touchMoveHandler(event)}
      onTouchEnd={event => touchEndHandler(event)}
    >
      {/* for refresh page only at Home */}
      <GlobalStyle currentView={context.currentView} />
      <BackDropTitle
        animation={backDropState.animation}
        fade={backDropState.fade}
        isMenuOpen={context.isMenuOpen}
        style={{ opacity: context.nextView !== context.currentView ? 0.05 : 0 }}
        onAnimationEnd={onBackDropAnimationEnd}
      >
        {MenuItems[context.currentView]}
      </BackDropTitle>
      <LeftSideBar />
      <Inner>
        <Header />
        <MenuMobile />
        <MainContent
          isMenuOpen={context.isMenuOpen}
          style={{
            transform: `scale3d(1, ${
              (context.currentView === 1 && scrollingState.scrollSign > 0) ||
              (context.currentView === 6 && scrollingState.scrollSign < 0) ||
              (context.currentView > 1 && context.currentView < 6)
                ? scrollingState.scrollScale
                : 1
            }, 1)`,
            transformOrigin: scrollingState.scrollSign >= 0 ? "bottom" : "top",
          }}
        >
          {getViewByName(context.currentView)}
        </MainContent>
        <Footer />
      </Inner>
      <RightSideBar>
        <Navigator />
      </RightSideBar>
    </Outer>
  );
};

export default React.memo(Wrapper);
