import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { TextBlock } from "../../styles";
import Content from "../Content/Content";
import { MenuItems } from "../../context";
import Title from "../Title/Title";

const Cont = styled.div`
  > div:not(:last-child) {
    ${tw`mr-20`}
  }

  ${tw`
    w-full
    flex flex-row items-start justify-between
    text-sm mobile-l:text-base laptop:text-lg
  `}
`;

const Contacts = styled.div`
  > div:not(:last-child) {
    ${tw`mb-4`}
  }

  ${tw`
    w-full mobile-l:w-auto mobile-l:ml-auto
    flex flex-col items-start justify-start
    pt-[10vmin] pr-8 laptop:pr-0
  `}
  a {
    ${tw`
      underline
    `}
  }
`;

const FW = styled.div`
  ${tw`
    flex flex-col items-start justify-start
    text-left
    pt-[10vmin]
  `}

  ${TextBlock} {
    &:first-child {
      ${tw`
        font-medium text-[6vmin] leading-none
      `}
    }
    &:last-child {
      ${tw`
          text-primaryColor text-[12vmin] leading-none font-bold
      `}
    }
  }
`;

const InnerContent = styled.div`
  ${tw`
    w-full h-full
    flex flex-col laptop:flex-row items-start justify-start
  `}
`;

// const DirectButton = styled.a`
//   ${tw`
//     relative
//     -left-12 laptop:left-0
//     h-16 w-64 laptop:w-72 my-12
//     bg-secondColor
//     cursor-pointer
//   `}
//
//   > div {
//     ${tw`
//       w-full h-full
//       px-12
//       flex flex-row items-center justify-between
//     `}
//     > ${TextBlock} {
//       ${tw`
//        font-medium text-sm phablet:text-base laptop:text-xl
//     `}
//     }
//   }
// `;

const contacts = [
  { id: 0, name: "Tel", value: <a href="tel:+33752975597">+33752975597</a> },
  { id: 1, name: "Email", value: <a href="mailto:inbox@jst.design">inbox@jst.design</a> },
];

const Contact = () => {
  return (
    <Content view={MenuItems.Contact}>
      <Title>Contact</Title>
      <InnerContent>
        <FW>
          <TextBlock>Always available for</TextBlock>
          <TextBlock>
            freelance <br />
            work
          </TextBlock>
        </FW>
        <Contacts>
          {contacts.map(contact => (
            <Cont key={`cont_${contact.id}`}>
              <TextBlock>{contact.name}:</TextBlock>
              {contact.value}
            </Cont>
          ))}
        </Contacts>
      </InnerContent>
      {/* <DirectButton href="mailto:inbox@jst.design"> */}
      {/*  <div> */}
      {/*    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="21" viewBox="0 0 33 21"> */}
      {/*      <g transform="translate(0.5 0.5)"> */}
      {/*        <path */}
      {/*          id="Path_8" */}
      {/*          data-name="Path 8" */}
      {/*          d="M10.5,45.5h32v-20h-32L26.7,35.7l15.4-9.4" */}
      {/*          transform="translate(-10.5 -25.5)" */}
      {/*          fill="none" */}
      {/*          stroke="#fff" */}
      {/*          strokeLinecap="round" */}
      {/*          strokeLinejoin="round" */}
      {/*          strokeWidth="1" */}
      {/*        /> */}
      {/*        <line */}
      {/*          id="Line_23" */}
      {/*          data-name="Line 23" */}
      {/*          x2="6.5" */}
      {/*          transform="translate(0 15.6)" */}
      {/*          fill="none" */}
      {/*          stroke="#fff" */}
      {/*          strokeLinecap="round" */}
      {/*          strokeLinejoin="round" */}
      {/*          strokeWidth="1" */}
      {/*        /> */}
      {/*        <line */}
      {/*          id="Line_24" */}
      {/*          data-name="Line 24" */}
      {/*          x2="6.5" */}
      {/*          transform="translate(0 11.2)" */}
      {/*          fill="none" */}
      {/*          stroke="#fff" */}
      {/*          strokeLinecap="round" */}
      {/*          strokeLinejoin="round" */}
      {/*          strokeWidth="1" */}
      {/*        /> */}
      {/*      </g> */}
      {/*    </svg> */}
      {/*    <TextBlock>Direct E-mail</TextBlock> */}
      {/*  </div> */}
      {/* </DirectButton> */}
    </Content>
  );
};

export default React.memo(Contact);
