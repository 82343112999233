import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { TextBlock } from "../../styles";
import BoxedButton from "../BoxedButton/BoxedButton";
import { MenuItems } from "../../context";
import Content from "../Content/Content";

const Hello = styled.div`
  ${tw`
    flex flex-col items-start justify-start pt-[10vmin]
    text-left
  `}
  ${TextBlock} {
    &:first-child {
      font-size: 6vmin;
      line-height: 1;
      ${tw`
        font-medium
      `}
    }

    &:last-child {
      font-size: 12vmin;
      line-height: 1;
      ${tw`
          text-primaryColor font-bold
      `}
    }
  }
`;

const Button = styled.button`
  ${TextBlock} {
    ${tw`
      ml-6
    `}
  }

  hr {
    ${tw`
      w-16 border-secondColor
    `}
  }
`;

const N = styled.div`
  > button:not(:last-child) {
    margin-bottom: 2rem;
  }

  ${tw`
    w-full pb-12 laptop:pb-0
    mt-auto
    flex flex-col items-start justify-start phablet:items-end
    laptop:self-end
  `}

  > ${Button} {
    ${tw`
      font-medium
      desktop:text-2xl laptop:text-xl tablet:text-lg phablet:text-base text-sm
    `}
  }
`;

const Home = () => {
  return (
    <Content view={MenuItems.Home}>
      <Hello>
        <TextBlock>Hello, I&apos;m a</TextBlock>
        <TextBlock>
          Motion <br />
          Designer
        </TextBlock>
      </Hello>
      <N>
        <BoxedButton view="Showreel" />
        <BoxedButton view="Contact" />
      </N>
    </Content>
  );
};

export default React.memo(Home);
