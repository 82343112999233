import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import Title from "../Title/Title";
import Skill from "../Skill/Skill";
import Content from "../Content/Content";
import { MenuItems } from "../../context";
import { TextBlock } from "../../styles";

const Description = styled.div`
  ${tw`
    w-full laptop:w-5/12 pb-8
    flex flex-col items-start laptop:items-end justify-start
    text-left laptop:text-right
    order-1 laptop:order-2
    text-xs mobile-l:text-base laptop:text-lg
  `}

  > div:not(:last-child) {
    ${tw`
      pb-8
    `}
  }

  ${TextBlock} {
    ${tw`
      text-left laptop:text-right
    `}
  }
`;

const Languages = styled.div`
  ${tw`
    w-full
    flex flex-col items-start laptop:items-end justify-start
  `}

  > ${TextBlock} {
    ${tw`
      italic font-semibold
      pb-4
    `}
  }

  > div:last-child {
    ${tw`
      w-full
      flex flex-row items-end justify-end
    `}

    > div {
      ${tw`
        w-full
        flex flex-col items-start justify-start
      `}

      > ${TextBlock} {
        ${tw`
          w-full
        `}
      }
    }

    > div {
      ${tw`
        w-1/2
      `}
    }
    > div:first-child {
      ${tw`
        font-semibold
      `}
    }

    > div:last-child {
      ${tw`
        font-light
      `}
    }

    > div:not(:last-child) {
      ${tw`
        pr-8
      `}
    }
  }
`;

const SkillsList = styled.div`
  ${tw`
    w-full laptop:w-7/12
    flex flex-row items-start justify-start flex-wrap phablet:flex-nowrap
    order-2 laptop:order-1
  `}
`;

const SkillsHalfList = styled.div`
  ${tw`
    w-full
    flex flex-row items-start justify-start
  `}

  > div {
    ${tw`
      w-1/2 phablet:w-full
      py-4
    `}
  }
`;

const SkillsContent = styled.div`
  ${tw`
    w-full h-full pt-8 laptop:pt-16
    flex flex-col laptop:flex-row items-start justify-start laptop:justify-between
  `}
`;

const skills = [
  [
    {
      id: 0,
      name: "Adobe",
      list: [
        { id: 0, name: "After Effects" },
        { id: 1, name: "Premiere Pro" },
        { id: 2, name: "Illustrator" },
        { id: 3, name: "Photoshop" },
        { id: 4, name: "Lightroom" },
        { id: 5, name: "InDesign" },
        { id: 6, name: "XD" },
      ],
    },
    {
      id: 1,
      name: "AV",
      list: [
        { id: 0, name: "TouchDesigner" },
        { id: 1, name: "Resolume" },
        { id: 2, name: "Ableton" },
        { id: 3, name: "MA3 (basics)" },
        { id: 4, name: "vMix" },
      ],
    },
  ],
  [
    {
      id: 2,
      name: "3D",
      list: [
        { id: 0, name: "Cinema 4D" },
        { id: 1, name: "SpeedTree" },
      ],
    },
    {
      id: 3,
      name: "Other",
      list: [
        { id: 0, name: "Figma" },
        { id: 1, name: "Asana" },
        { id: 2, name: "Slack" },
        { id: 3, name: "G Docs" },
      ],
    },
  ],
];

const Skills = () => {
  return (
    <Content view={MenuItems.Skills}>
      <Title>Skills</Title>
      <SkillsContent>
        <SkillsList>
          {skills.map(skillsHalf => (
            <SkillsHalfList key={`skill_row_${skillsHalf[0].id}`}>
              {skillsHalf.map(skill => (
                <Skill key={`skill_col_${skill.id}`} name={skill.name} list={skill.list} />
              ))}
            </SkillsHalfList>
          ))}
        </SkillsList>
        <Description>
          <TextBlock>
            List of my skills I&apos;ve been developing and
            <br />
            learning through my entire career.
            <br />
            I&apos;m always learning and searching for new
            <br /> things that I like to add into my skill collection.
          </TextBlock>
          <Languages>
            <TextBlock>Languages:</TextBlock>
            <div>
              <div>
                <TextBlock>English</TextBlock>
                <TextBlock>French</TextBlock>
                <TextBlock>Russian</TextBlock>
              </div>
              <div>
                <TextBlock>Advanced</TextBlock>
                <TextBlock>Intermediate</TextBlock>
                <TextBlock>Native</TextBlock>
              </div>
            </div>
          </Languages>
        </Description>
      </SkillsContent>
    </Content>
  );
};

export default React.memo(Skills);
