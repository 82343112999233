import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { TextBlock } from "../../styles";
import Title from "../Title/Title";
import { MenuItems } from "../../context";
import Content from "../Content/Content";

const PhotoBlock = styled.div`
  ${tw`
    relative
    w-full h-full
    -left-8 mobile-l:left-0
  `}

  background-image: url("/images/oskar.jpg");
  background-repeat: no-repeat;
  background-size: contain;
`;

const Name = styled.div`
  ${tw`
    w-full
    flex flex-col items-start justify-center
    uppercase
  `}

  > div:not(:last-child) {
    ${tw`mb-4 desktop:mb-8`}
  }

  ${TextBlock}:nth-child(1) {
    line-height: 1 !important;
    ${tw`
      mt-4
      text-xl mobile-l:text-2xl laptop:text-2xl desktop:text-3xl font-bold tracking-[0.25em]
    `}
  }
  ${TextBlock}:nth-child(2) {
    line-height: 1 !important;
    ${tw`
      font-semibold tracking-[0.1em]
    `}
  }
`;

const Name1 = (
  <>
    <TextBlock>Nikita Osipov</TextBlock>
    <TextBlock>Motion Designer / VJ</TextBlock>
  </>
);

const Text = styled.div`
  ${tw`
    relative w-max phablet:pl-4
    flex flex-col items-start justify-center
  `}

  > div:not(:last-child) {
    ${tw`mb-4 desktop:mb-8`}
  }

  ${TextBlock} {
    ${tw`
      w-full
      text-left tablet:text-right
    `}
  }

  > ${TextBlock} {
    ${tw`
      landscape:leading-normal font-light
    `}
  }
`;

const InnerContent = styled.div<{ isRow: boolean }>`
  ${tw`
    w-full h-full relative
    flex flex-col items-start
  `}
  ${props => (props.isRow ? tw`justify-between` : tw`justify-start`)}
  
  > ${Text} {
    ${props => !props.isRow && tw`tablet:self-end`};
  }
`;

enum Direction {
  Row,
  Column,
}

const getDirection = (num: number) => {
  if (num === 0) return "row";
  return "column";
};

type AboutState = {
  photoWidth: number;
  photoHeight: number;
  direction: { flexDirection: Direction };
  isUnderTablet: boolean;
};

const About = () => {
  const [state, setState] = useState<AboutState>({
    photoWidth: 0,
    photoHeight: 0,
    direction: { flexDirection: Direction.Column },
    isUnderTablet: true,
  });

  const innerContentRef: React.RefObject<HTMLDivElement> = useRef(null);
  const photoBlockRef: React.RefObject<HTMLDivElement> = useRef(null);
  const textBlockRef: React.RefObject<HTMLDivElement> = useRef(null);
  const nameBlockRef: React.RefObject<HTMLDivElement> = useRef(null);

  const handler = () => {
    const bodyWidth = window.innerWidth || document.documentElement.clientWidth;
    if (innerContentRef.current && photoBlockRef.current && textBlockRef.current) {
      const parentHeight = innerContentRef.current.getBoundingClientRect().height;
      const parentWidth = innerContentRef.current.getBoundingClientRect().width;
      const textBlockHeight = textBlockRef.current.getBoundingClientRect().height;
      const textBlockWidth = textBlockRef.current.getBoundingClientRect().width;

      let photoBlockWidth =
        (parentWidth - textBlockWidth) * 0.5625 > parentHeight * 0.8 ? parentHeight * 0.8 * (16 / 9) : parentWidth - textBlockWidth;
      if (nameBlockRef.current) {
        const nameBlockWidth = nameBlockRef.current.getBoundingClientRect().width;
        const offsetWidth = textBlockWidth > nameBlockWidth ? textBlockWidth : nameBlockWidth;

        photoBlockWidth =
          (parentWidth - offsetWidth) * 0.5625 > parentHeight * 0.8 ? parentHeight * 0.8 * (16 / 9) : parentWidth - offsetWidth;
      }

      const photoBlockHeight = photoBlockWidth * 0.5625;

      if (photoBlockHeight + textBlockHeight >= parentHeight) {
        if (photoBlockWidth > parentWidth / 2) {
          setState({
            photoWidth: parentWidth / 2,
            photoHeight: (parentWidth / 2) * 0.5625,
            direction: { flexDirection: Direction.Row },
            isUnderTablet: bodyWidth < 768,
          });
        } else {
          setState({
            photoWidth: photoBlockWidth,
            photoHeight: photoBlockHeight,
            direction: { flexDirection: Direction.Row },
            isUnderTablet: bodyWidth < 768,
          });
        }
      } else {
        const padding = 2 * 32; // 2 * 2 rem

        let columnPhotoBlockWidth =
          parentWidth * 0.5625 + textBlockHeight + padding < parentHeight
            ? parentWidth
            : (parentHeight - textBlockHeight - padding) * 0.8 * (16 / 9);

        if (nameBlockRef.current) {
          const nameBlockHeight = nameBlockRef.current.getBoundingClientRect().height;

          columnPhotoBlockWidth =
            parentWidth * 0.5625 + textBlockHeight + nameBlockHeight + padding < parentHeight
              ? parentWidth
              : (parentHeight - textBlockHeight - nameBlockHeight - padding) * 0.8 * (16 / 9);
        }

        const columnPhotoBlockHeight = columnPhotoBlockWidth * 0.5625;

        setState({
          photoWidth: columnPhotoBlockWidth,
          photoHeight: columnPhotoBlockHeight,
          direction: { flexDirection: Direction.Column },
          isUnderTablet: bodyWidth < 768,
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handler);
    window.addEventListener("animationstart", handler);
    return () => {
      window.removeEventListener("resize", handler);
      window.removeEventListener("animationstart", handler);
    };
  }, []);

  return (
    <Content view={MenuItems.About}>
      <Title>About me</Title>
      <InnerContent
        isRow={state.direction.flexDirection === Direction.Row}
        ref={innerContentRef}
        style={{
          flexDirection: getDirection(state.direction.flexDirection),
          marginTop: state.direction.flexDirection === Direction.Row ? "2rem" : 0,
        }}
      >
        {state.isUnderTablet && <Name ref={nameBlockRef}>{Name1}</Name>}
        <PhotoBlock
          ref={photoBlockRef}
          style={{
            height: state.photoHeight,
            width: state.photoWidth,
            marginTop: state.direction.flexDirection === Direction.Column ? "2rem" : 0,
            marginBottom: state.direction.flexDirection === Direction.Column ? "2rem" : 0,
          }}
        />
        <Text
          ref={textBlockRef}
          style={{
            paddingLeft: state.direction.flexDirection === Direction.Row ? "2rem" : 0,
          }}
        >
          {!state.isUnderTablet && <Name>{Name1}</Name>}
          <TextBlock>
            I&apos;ve been freelancing for 3 years, I love
            <br />
            creating and am passionate about every project.
          </TextBlock>
          <TextBlock>
            I create visuals for expo stands/displays,
            <br />
            videos for seminars, motion graphics,
            <br />
            compositing work, transitions,
            <br />
            display mappings, graphic designs etc.
            <br />
            I also do live VJ performances with my own
            <br />
            visuals or with any other.
          </TextBlock>
        </Text>
      </InnerContent>
    </Content>
  );
};

export default React.memo(About);
