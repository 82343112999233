import React, { useContext } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ViewContext, MenuItems, animationDuration, ScrollState } from "../../context";
import MenuButton from "../MenuButton/MenuButton";
import { TextBlock, UpButton } from "../../styles";

const Logo = styled(TextBlock)<{ isHome: boolean }>`
  ${tw`
    relative
    w-auto h-full relative cursor-pointer leading-none
    flex flex-row items-center justify-center
    border-primaryColor
    select-none
    text-sm phablet:text-base
  `}

  > hr {
    ${tw`
      absolute
      w-0
      -left-8 laptop:-left-16 desktop:-left-24
      border-t-2 border-primaryColor
    `}
    transition: width ${animationDuration - 0.05}s ease-in;
    ${props => (props.isHome ? tw`w-8 laptop:w-16 desktop:w-24` : null)};
  }

  > ${TextBlock} {
    ${tw`
      relative
    `}
    padding-left: ${props => (props.isHome ? "1rem" : null)};
    transition: padding ${animationDuration}s ease-in;

    > span:first-of-type {
      ${tw`
      font-normal
      text-primaryColor
    `}
    }
    > span:last-of-type {
      ${tw`
      font-semibold
    `}
    }
  }
`;

const Spacer = styled.div`
  ${tw`
    w-auto mx-auto
  `}
`;

const Content = styled.div`
  ${tw`
    w-full h-8 relative
    flex flex-row items-center justify-center
  `}
`;

const Header = () => {
  const context = useContext(ViewContext);

  const UpButtonHandler = () => {
    const { currentView } = context;
    if (currentView > 1 && currentView <= 6) {
      context.setNextView(currentView - 1);
    }
  };

  const onClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();

    context.setMenuState(false);
    context.setNextView(MenuItems.Home);
  };

  return (
    <Content>
      <Logo
        isHome={context.currentView === MenuItems.Home && (context.nextView === null || context.nextView === MenuItems.Home)}
        onClick={event => onClickHandler(event)}
      >
        <hr />
        <TextBlock>
          <span>jst</span>.<span>design</span>
        </TextBlock>
      </Logo>
      {context.currentView !== MenuItems.Home &&
        !context.isMenuOpen &&
        context.scrollState !== ScrollState.BETWEEN &&
        context.scrollState !== ScrollState.DOWN && (
          <UpButton onClick={() => UpButtonHandler()}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 18" xmlSpace="preserve">
              <path d="M 0 17 16 1 32 17" fill="none" />
            </svg>
          </UpButton>
        )}
      <Spacer />
      <MenuButton />
    </Content>
  );
};

export default React.memo(Header);
