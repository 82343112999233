import { createContext } from "react";

export type ViewIndex = number;
export type ViewType = "Menu" | "Home" | "About" | "Showreel" | "Skills" | "Services" | "Contact";

type NavMenuType = {
  id: ViewIndex;
  name: ViewType;
};

enum MenuItems {
  "Menu",
  "Home",
  "About",
  "Showreel",
  "Skills",
  "Services",
  "Contact",
}

enum ScrollState {
  NONE,
  UP,
  DOWN,
  BETWEEN,
}

const menuItems: NavMenuType[] = [
  { id: 1, name: "Home" },
  { id: 2, name: "About" },
  { id: 3, name: "Showreel" },
  { id: 4, name: "Skills" },
  { id: 5, name: "Services" },
  { id: 6, name: "Contact" },
];

type ViewContextType = {
  previousView: 0 | ViewIndex;
  currentView: ViewIndex;
  nextView: ViewIndex | null;
  isMenuOpen: boolean;
  scrollState: ScrollState;
  changeView: () => void;
  clearNextView: () => void;
  setNextView: (view: number) => void;
  setMenuState: (state: boolean) => void;
  setScrollState: (position: ScrollState) => void;
};

const animationDuration = 0.3;

const ViewContext = createContext<ViewContextType>({
  previousView: 1,
  currentView: 1,
  nextView: 1,
  isMenuOpen: false,
  scrollState: ScrollState.BETWEEN,
  changeView: () => {},
  clearNextView: () => {},
  setNextView: () => {},
  setMenuState: () => {},
  setScrollState: () => {},
});

export { ViewContext, menuItems, MenuItems, ScrollState, animationDuration };
