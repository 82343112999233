import tw from "twin.macro";
import styled from "styled-components";

const TextBlock = styled.div`
  ${tw`
    inline
    text-xs mobile-l:text-sm laptop:text-base
  `}
`;

const ControlButton = styled.button`
  ${tw`
    w-5 h-5 laptop:w-8 laptop:h-8 absolute text-primaryColor
  `}
  > svg {
    ${tw`
      stroke-current    
    `}
  }
`;

const UpButton = styled(ControlButton)``;

const DownButton = styled(ControlButton)``;

export { TextBlock, UpButton, DownButton };
