import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import tw from "twin.macro";
import { TextBlock } from "../../styles";

type TitleProps = {
  subtitle?: boolean;
  children: React.ReactNode;
};
const TitlePropTypes = {
  subtitle: PropTypes.bool,
};

const Content = styled.div<{ subtitle: boolean }>`
  ${tw`
    relative
    w-full
    flex flex-row items-center justify-start phablet:justify-end
  `}
  ${TextBlock} {
    ${tw`
      font-semibold uppercase tracking-widest
    `}
  }
  hr {
    ${tw`
      absolute
      -left-64 right-auto phablet:left-auto phablet:right-60
      w-60 border-t-2
      border-primaryColor
    `}
  }
`;

const Title = ({ subtitle, children }: TitleProps) => {
  return (
    <Content subtitle={subtitle as boolean}>
      <hr />
      <TextBlock>{children}</TextBlock>
    </Content>
  );
};

Title.propTypes = TitlePropTypes;
Title.defaultProps = {
  subtitle: false,
};

export default React.memo(Title);
