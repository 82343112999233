import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { animationDuration, menuItems, ViewContext } from "../../context";
import { TextBlock } from "../../styles";

const getAnimationString = (duration: number, delay: number, child: number, fade: "in" | "out") => {
  return `
  &:nth-child(${child}) {
    opacity: 0;
    animation: ${duration}s ${delay}s forwards ${fade === "in" ? "reverse" : ""} ease-in fade, 
    ${duration}s ${delay}s forwards ${fade === "in" ? "reverse" : ""} ease-in menuSlide;
  }
  `;
};

const menuAnimation = (fade: "in" | "out" | null) => {
  const delay = 0.025;
  const child = 6;
  const duration = animationDuration - delay * child;
  if (fade !== null) {
    let animation = "";
    for (let x = 1; x <= child; x += 1) {
      animation += getAnimationString(duration, parseFloat((delay * x).toFixed(4)), x, fade);
    }
    return animation;
  }
  return "";
};

const MenuItem = styled.button`
  ${tw`
    relative w-[calc(100% - 1rem)] phablet:w-[60vw] laptop:w-[30vw] h-full
    bg-secondColor
    flex flex-row items-center justify-center
    tablet:text-xl phablet:text-lg text-base
  `}
  flex: 1 1 ${100 / 6}%;

  > ${TextBlock} {
    ${tw`
      tracking-[0.5vw] ml-[0.5vw] uppercase
    `}
    transition: all 0.3s ease-in;
  }
  &:hover ${TextBlock} {
    ${tw`
      tracking-[0.7vw] ml-[0.7vw]
    `}
    transition: all 0.3s ease-in;
  }
`;

const Content = styled.div<{ fade: "in" | "out" | null; isMenuOpen: boolean }>`
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    > button:not(:last-child) {
      margin-bottom: 2vh;
    }
  }

  ${tw`
    w-full laptop:w-min h-[80%] absolute z-50
    flex flex-col items-center laptop:items-end justify-center gap-y-[2vh]
    right-1 laptop:right-10 desktop:right-[4.5rem]
  `}

  ${MenuItem} {
    ${props => menuAnimation(props.fade)}
  }
`;

const HL = styled.hr`
  ${tw`
    w-6 mx-4
    border-t-2
  `}
`;

type MenuMobileState = {
  active: boolean;
  fade: "in" | "out" | null;
};

const MenuMobile = () => {
  const context = useContext(ViewContext);
  const [state, setState] = useState<MenuMobileState>({ active: false, fade: null });

  useEffect(() => {
    if (context.isMenuOpen && state.active) {
      setState(prevState => ({ ...prevState, ...{ fade: null } }));
    }
    if (context.isMenuOpen && !state.active) {
      setState(prevState => ({ ...prevState, ...{ fade: "in" } }));
    }
    if (!context.isMenuOpen && state.active) {
      setState(prevState => ({ ...prevState, ...{ fade: "out" } }));
    }
  }, [context.isMenuOpen, state.active]);

  const onAnimationEndHandler = (id: number) => {
    if (id === 6 && context.isMenuOpen) {
      setState(prevState => ({ ...prevState, ...{ active: true } }));
    } else if (id === 6 && !context.isMenuOpen) {
      setState(prevState => ({ ...prevState, ...{ active: false } }));
    }
  };

  return (
    <Content
      id="menu"
      fade={state.fade}
      isMenuOpen={context.isMenuOpen}
      style={{ display: context.isMenuOpen || state.active ? "flex" : "none" }}
    >
      {menuItems.map(item => {
        return (
          <MenuItem
            key={`menu_item_${item.id}`}
            onClick={() => {
              context.setMenuState(false);
              context.setNextView(item.id);
            }}
            onAnimationEnd={() => onAnimationEndHandler(item.id)}
            style={{ opacity: state.active ? 1 : 0 }}
          >
            {context.currentView === item.id && <HL />}
            <TextBlock>{item.name}</TextBlock>
            {context.currentView === item.id && <HL />}
          </MenuItem>
        );
      })}
    </Content>
  );
};
export default React.memo(MenuMobile);
