import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import Title from "../Title";
import Content from "../Content";
import { animationDuration, MenuItems } from "../../context";
import VideoPlayer from "../VideoPlayer";
import { TextBlock } from "../../styles";
import BoxedButton from "../BoxedButton/BoxedButton";

const VideoBlock = styled.div`
  ${tw`
    absolute
    w-11/12 phablet:w-7/12 h-full
    -left-8 mobile-l:left-0
  `}
`;

const GalleryBlock = styled.div`
  ${tw`
    absolute
    w-7/12 h-full
    flex flex-row items-start phablet:items-center justify-start
  `}

  > ${TextBlock} {
    ${tw`
      tracking-widest
      text-base laptop:text-2xl
    `}
  }
`;

const Button = styled.button<{ isActive: boolean }>`
  ${tw`
    font-semibold uppercase tracking-[0.4vw]
    mx-4
  `};
  ${props => (props.isActive ? tw`text-white` : tw`text-primaryColor`)}
`;

const D = styled.div`
  ${tw`
    relative w-full phablet:w-10/12 tablet:w-7/12
    flex flex-row items-center justify-start
    -left-8 laptop:-left-16 desktop:-left-24
  `}

  > hr {
    ${tw`
      border-t-2 border-primaryColor
    `}
    flex-shrink: 0;
    &:nth-of-type(1) {
      ${tw`
        w-4 phablet:flex-grow
      `}
    }
    &:nth-of-type(2) {
      ${tw`
        w-4 phablet:w-8 desktop:w-16
      `}
    }
    &:nth-of-type(3) {
      ${tw`
        w-4 phablet:w-8 desktop:w-16
      `}
    }
  }
`;

enum ContentState {
  SHOWREEL,
  GALLERY,
}

const E = styled.div`
  ${tw`
    w-full
    mb-12 laptop:mb-0
    flex flex-col phablet:flex-row items-start justify-start phablet:justify-between
  `}

  > button {
    ${tw`
      mt-12 phablet:mt-0
    `}
  }
`;

const InnerContent = styled.div`
  ${tw`
    relative
    w-full h-full relative mt-8
    flex flex-col items-start justify-start
  `}
`;

type ShowreelState = {
  content: ContentState;
  firstLoad: boolean;
};

const setAnimation = (type: "in" | "out") => {
  return `${type === "in" ? `${animationDuration * 2}s` : `${animationDuration}s`} forwards ease-in showreelSlide${
    type === "in" ? "In" : "Out"
  }`;
};

const Showreel = () => {
  const [state, setState] = useState<ShowreelState>({
    content: ContentState.SHOWREEL,
    firstLoad: true,
  });

  useEffect(() => {
    const VideoBlockRef = document.getElementById("VideoBlock");
    const GalleryBlockRef = document.getElementById("GalleryBlock");
    if (VideoBlockRef && GalleryBlockRef) {
      if (!state.firstLoad) {
        if (state.content === ContentState.SHOWREEL) {
          VideoBlockRef.style.display = "";
          VideoBlockRef.style.animation = setAnimation("in");
          GalleryBlockRef.style.animation = setAnimation("out");
        }
        if (state.content === ContentState.GALLERY) {
          GalleryBlockRef.style.display = "";
          VideoBlockRef.style.animation = setAnimation("out");
          GalleryBlockRef.style.animation = setAnimation("in");
        }
      } else {
        if (state.content === ContentState.SHOWREEL) {
          GalleryBlockRef.style.display = "none";
        }
        if (state.content === ContentState.GALLERY) {
          VideoBlockRef.style.display = "none";
        }
      }
    }
  }, [state.content, state.firstLoad]);

  const onAnimationEndHandler = (event: React.AnimationEvent<HTMLDivElement>, content: ContentState) => {
    if (state.content === ContentState.SHOWREEL && content === ContentState.GALLERY) {
      const GalleryBlockRef = document.getElementById("GalleryBlock");
      if (GalleryBlockRef) {
        GalleryBlockRef.style.display = "none";
      }
    }
    if (state.content === ContentState.GALLERY && content === ContentState.SHOWREEL) {
      const VideoBlockRef = document.getElementById("VideoBlock");
      if (VideoBlockRef) {
        VideoBlockRef.style.display = "none";
      }
    }
  };

  const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>, content: ContentState) => {
    event.preventDefault();

    setState({ content, firstLoad: false });
  };

  return (
    <Content view={MenuItems.Showreel}>
      <Title>My work</Title>
      <InnerContent>
        <VideoBlock id="VideoBlock" onAnimationEnd={event => onAnimationEndHandler(event, ContentState.SHOWREEL)}>
          <VideoPlayer isPlayable={state.content === ContentState.SHOWREEL} />
        </VideoBlock>
        <GalleryBlock id="GalleryBlock" onAnimationEnd={event => onAnimationEndHandler(event, ContentState.GALLERY)}>
          <TextBlock>COMING SOON...</TextBlock>
        </GalleryBlock>
      </InnerContent>
      <E>
        <D>
          <hr />
          <Button isActive={state.content === ContentState.SHOWREEL} onClick={event => onClickHandler(event, ContentState.SHOWREEL)}>
            <TextBlock>Showreel</TextBlock>
          </Button>
          <hr />
          <Button isActive={state.content === ContentState.GALLERY} onClick={event => onClickHandler(event, ContentState.GALLERY)}>
            <TextBlock>Gallery</TextBlock>
          </Button>
          <hr />
        </D>
        <BoxedButton view="Contact" />
      </E>
    </Content>
  );
};

export default React.memo(Showreel);
