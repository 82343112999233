import React, { useContext } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import PropTypes from "prop-types";
import { TextBlock } from "../../styles";
import { MenuItems, ViewContext, ViewType } from "../../context";

const Button = styled.button`
  ${tw`
    relative w-32 phablet:w-40 laptop:w-44 desktop:w-52 h-auto
  `}
  > svg {
    ${tw`
      relative w-full
      -top-1 phablet:-top-3
      text-secondColor
      stroke-current
    `}
  }
  > ${TextBlock} {
    ${tw`
      relative pl-2 z-10
      flex flex-row items-center justify-start
      desktop:text-xl laptop:text-lg tablet:text-base phablet:text-base text-sm font-medium tracking-[0.3em] leading-none
      uppercase
    `}
  }
`;

const BoxedButtonPropTypes = {
  view: PropTypes.string.isRequired,
};

type BoxedButtonProps = React.ComponentPropsWithoutRef<"button"> & {
  view: ViewType;
};

const BoxedButton = (props: BoxedButtonProps) => {
  const context = useContext(ViewContext);
  const { view, ...restProps } = props;

  const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    context.setMenuState(false);
    context.setNextView(MenuItems[view]);
  };

  return (
    <Button onClick={event => onClickHandler(event)} {...restProps}>
      <TextBlock>{view}</TextBlock>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 160 16" xmlSpace="preserve" fill="none">
        {/* <polygon points="1,6 1,19 62,19 67,14 67,1 6,1 " /> */}
        <polygon points="0,0 100,0 100,10 0,10" fill="#1488AF" />
      </svg>
    </Button>
  );
};

BoxedButton.propTypes = BoxedButtonPropTypes;
BoxedButton.defaultProps = {};

export default React.memo(BoxedButton);
