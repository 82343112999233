import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { TextBlock } from "../../styles";
import Title from "../Title/Title";
import Content from "../Content/Content";
import { MenuItems } from "../../context";

const Description = styled.div`
  ${tw`
    w-full
    pt-8 laptop:pt-16
    flex flex-col items-start laptop:items-end justify-start
    text-left laptop:text-right
    whitespace-pre-line
  `}
`;

const Service = styled.div`
  ${tw`
    w-full phablet:w-2/4 laptop:w-[29.166668%] h-auto pr-8
    flex flex-col items-start justify-center
  `}

  > div:not(:last-child) {
    ${tw`mb-4`}
  }

  > ${TextBlock} {
    ${tw`
      font-light text-left
    `}

    &:first-child {
      ${tw`
        uppercase
        text-xl mobile-l:text-lg laptop:text-xl desktop:text-2xl tracking-[0.24em] desktop:tracking-[0.18em] font-bold
      `}
    }
    &:last-child {
      ${tw`
        desktop:w-3/4  
      `}
    }
  }
`;

const ServicesList = styled.div`
  > div:not(:last-child) {
    ${tw`mb-8`}
  }

  ${tw`
    w-full h-full pt-8 laptop:pt-16
    flex flex-col laptop:flex-row items-start justify-start
  `}

  > div:first-child > div:first-child {
    ${tw`
      laptop:pr-[14.4px] desktop:pr-[15.75px]
    `}
  }
`;

const services = [
  {
    id: 0,
    subtitle: "Motion Design",
    desc: "Video content for events, media, presentations, displays, stands. Motion graphics, animations, transitions, compositing etc.",
  },
  { id: 1, subtitle: "Graphic Design", desc: "Logo, posters, flyers, business cards etc." },
  { id: 2, subtitle: "AV", desc: "Live visual performance, screen mapping." },
];

const Services = () => {
  return (
    <Content view={MenuItems.Services}>
      <Title>Services</Title>
      <Description>
        <TextBlock>
          {`I provide a range of services and I would
            love to help you find the best creative
            solution for your needs.`}
        </TextBlock>
      </Description>
      <ServicesList>
        {services.map(service => (
          <Service key={`service_${service.id}`}>
            <TextBlock>{service.subtitle}</TextBlock>
            <TextBlock>{service.desc}</TextBlock>
          </Service>
        ))}
      </ServicesList>
    </Content>
  );
};

export default React.memo(Services);
