import React, { RefObject, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import tw from "twin.macro";
import { animationDuration, ScrollState, ViewContext } from "../../context";

type ContentProps = {
  view: number;
  children: React.ReactNode;
};

type ContentState = {
  animation: "from-top" | "from-bottom" | null;
  fade: "in" | "out" | null;
};

const getAnimationString = (duration: number, delay: number, number: number, fade: "in" | "out", animation: "from-top" | "from-bottom") => {
  return `
  animation: ${duration}s ${(delay / 8) * number}s forwards ${fade === "in" ? "reverse" : ""} ease-in fade, ${duration}s ${
    (delay / 8) * number
  }s forwards ${fade === "in" ? "reverse" : ""} ease-in ${
    animation === "from-bottom" ? "translateFromBottomAnimation" : "translateFromTopAnimation"
  }`;
};

const parallax = (view: number, fade: "in" | "out" | null, animation: "from-top" | "from-bottom" | null) => {
  const delay = 0.3;
  const number = 0;
  if (fade !== null && animation !== null) {
    return getAnimationString(animationDuration, delay, number, fade, animation);
  }
  return "";
};

const ContentWrapper = styled.div<{ animation: "from-top" | "from-bottom" | null; fade: "in" | "out" | null; view: number }>`
  ${tw`
    w-full h-full
    flex flex-col items-start justify-start
    laptop:pr-12 desktop:pr-24
  `}

  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
  }

  ${props => parallax(props.view, props.fade, props.animation)}
`;

const Content = (props: ContentProps) => {
  const context = useContext(ViewContext);
  const { view, children } = props;
  const [state, setState] = useState<ContentState>({ animation: null, fade: null });

  useEffect(() => {
    if (context.nextView !== null) {
      if (context.currentView !== context.nextView) {
        // исчезает, контент уезжает вверх
        if (context.currentView < context.nextView) {
          return setState(prevState => ({ ...prevState, ...{ fade: "out", animation: "from-bottom" } }));
        }
        if (context.currentView > context.nextView) {
          return setState(prevState => ({ ...prevState, ...{ fade: "out", animation: "from-top" } }));
        }
      }
      if (context.currentView === context.nextView) {
        // появляется, контент приезжает сверху
        if (context.currentView > context.previousView) {
          return setState(prevState => ({ ...prevState, ...{ fade: "in", animation: "from-top" } }));
        }
        if (context.currentView < context.previousView) {
          return setState(prevState => ({ ...prevState, ...{ fade: "in", animation: "from-bottom" } }));
        }
      }
    }
    return () => {};
  }, [context.currentView, context.nextView, context.previousView]);

  const animationEndHandler = () => {
    setState(prevState => ({ ...prevState, ...{ fade: null, position: null } }));
    if (context.nextView !== null) {
      if (context.currentView !== context.nextView) {
        context.changeView();
      }
      if (context.currentView === context.nextView) {
        context.clearNextView();
      }
    }
  };

  const contentWrapperRef: RefObject<HTMLDivElement> = React.useRef(null);

  // If content overflow and where it appeared
  useEffect(() => {
    const mainContent = contentWrapperRef.current;
    if (mainContent && context.nextView !== null) {
      if (
        mainContent.scrollHeight > mainContent.offsetHeight &&
        (mainContent.scrollHeight - mainContent.offsetHeight) / mainContent.scrollHeight > 0.1
      ) {
        mainContent.style.overflowY = "scroll";
        mainContent.style.scrollbarGutter = "none";
        if (context.currentView > context.previousView) {
          context.setScrollState(ScrollState.UP);
          // scroll to top if appear from top
          mainContent.scrollTop = 0;
        }
        if (context.currentView < context.previousView) {
          context.setScrollState(ScrollState.DOWN);
          // scroll to bottom if appear from bottom
          mainContent.scrollTop = mainContent.scrollHeight;
        }
      } else {
        context.setScrollState(ScrollState.NONE);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onScrollHandler = () => {
    const mainContent = contentWrapperRef.current;
    if (mainContent && context.scrollState !== ScrollState.NONE) {
      if (mainContent.scrollTop === 0) {
        context.setScrollState(ScrollState.UP);
      } else if (mainContent.scrollTop === mainContent.scrollHeight - mainContent.offsetHeight) {
        context.setScrollState(ScrollState.DOWN);
      } else {
        context.setScrollState(ScrollState.BETWEEN);
      }
    }
  };

  return (
    <ContentWrapper
      onAnimationEnd={animationEndHandler}
      animation={state.animation}
      style={{ opacity: context.nextView !== context.currentView ? 1 : 0 }}
      fade={state.fade}
      view={view}
      ref={contentWrapperRef}
      onScroll={onScrollHandler}
    >
      {children}
    </ContentWrapper>
  );
};

Content.propTypes = {
  view: PropTypes.oneOf([1, 2, 3, 4, 5, 6]).isRequired,
  children: PropTypes.node.isRequired,
};

export default React.memo(Content);
