import React, { useEffect, useState } from "react";
import { MenuItems, ScrollState, ViewContext } from "../context";
import Wrapper from "../components/Wrapper";

type ContextState = {
  previousView: number;
  currentView: number;
  nextView: number | null;
  isMenuOpen: boolean;
  scrollState: ScrollState;
};

const MainPage = () => {
  const [contextState, setContextState] = useState<ContextState>({
    previousView: MenuItems.Home,
    currentView: MenuItems.Home,
    nextView: null,
    isMenuOpen: false,
    scrollState: ScrollState.NONE,
  });

  const changeView = () => {
    if (contextState.nextView !== null) {
      setContextState(prevState => ({
        ...prevState,
        ...{ previousView: prevState.currentView, currentView: prevState.nextView!, scrollState: ScrollState.NONE },
      }));
    }
  };
  const clearNextView = () => {
    if (contextState.nextView !== null) {
      setContextState(prevState => ({
        ...prevState,
        ...{ nextView: null },
      }));
    }
  };
  const setNextView = (nextView: number) => {
    if (nextView >= 1 && nextView <= 6) {
      setContextState(prevState => ({ ...prevState, ...{ nextView } }));
    }
  };
  const setMenuState = (newMenuState: boolean) => {
    setContextState(prevState => ({ ...prevState, ...{ isMenuOpen: newMenuState } }));
  };
  const setScrollState = (position: ScrollState) => {
    setContextState(prevState => ({ ...prevState, ...{ scrollState: position } }));
  };
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue = { ...contextState, changeView, clearNextView, setNextView, setMenuState, setScrollState };

  useEffect(() => {}, []);

  return (
    <ViewContext.Provider value={contextValue}>
      <Wrapper />
    </ViewContext.Provider>
  );
};

export default React.memo(MainPage);
