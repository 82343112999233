import React, { useContext } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { MenuItems, ScrollState, ViewContext } from "../../context";
import { DownButton, TextBlock } from "../../styles";

const Content = styled.div`
  ${tw`
    w-full h-[32px] relative
    flex flex-row items-center justify-center
  `}
  ${TextBlock} {
    ${tw`
      laptop:ml-auto
      laptop:pr-12 desktop:pr-24
    `}
    font-size: 0.5rem;
  }
`;

const Footer = () => {
  const context = useContext(ViewContext);

  const DownButtonHandler = () => {
    const { currentView } = context;
    if (currentView >= 1 && currentView < 6) {
      context.setNextView(currentView + 1);
    }
  };

  return (
    <Content>
      {!context.isMenuOpen &&
        context.scrollState !== ScrollState.BETWEEN &&
        context.scrollState !== ScrollState.UP &&
        (context.currentView !== MenuItems.Contact ? (
          <DownButton onClick={() => DownButtonHandler()}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 18" xmlSpace="preserve">
              <path d="M 0 1 16 17 32 1" fill="none" />
            </svg>
          </DownButton>
        ) : (
          <TextBlock>jst.design © 2021. All rights reserved.</TextBlock>
        ))}
    </Content>
  );
};

export default React.memo(Footer);
