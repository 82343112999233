import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import tw from "twin.macro";
import { TextBlock } from "../../styles";

type SkillType = {
  name: string;
  list: { id: number; name: string }[];
};

const SkillPropTypes = {
  name: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number.isRequired, name: PropTypes.string.isRequired }).isRequired).isRequired,
};

const Content = styled.div`
  ${tw`
    w-full
    flex flex-col items-start justify-start 
  `}

  > ${TextBlock} {
    line-height: 1 !important;
    ${tw`
      pb-4 laptop:pb-8
      text-lg mobile-l:text-xl laptop:text-2xl desktop:text-2xl tracking-[0.22em] font-bold uppercase
    `}
  }

  > div:last-child {
    ${tw`
      flex flex-col items-start justify-start
    `}

    > ${TextBlock}:not(:last-child) {
      ${tw`
        pb-1 laptop:pb-2
      `}
    }
  }
`;

const Skill = ({ name, list }: SkillType) => {
  return (
    <Content>
      <TextBlock>{name}</TextBlock>
      <div>
        {list.map(skill => (
          <TextBlock key={`skill_${name}_${skill.id}`}>{skill.name}</TextBlock>
        ))}
      </div>
    </Content>
  );
};

Skill.propTypes = SkillPropTypes;
Skill.defaultProps = {};

export default React.memo(Skill);
