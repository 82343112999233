import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { animationDuration, MenuItems, menuItems, ViewContext } from "../../context";
import { TextBlock } from "../../styles";

const VL = styled.div`
  ${tw`
    border-primaryColor border-r-2
  `};
`;

const NavButton = styled.div<{ activeView: boolean; isMenuOpen: boolean }>`
  ${tw`
    w-2 py-[0.25vh]
    flex flex-row items-center justify-center
    text-primaryColor
    cursor-pointer
  `}
  ${props => props.isMenuOpen && tw`pointer-events-none`}

  > ${TextBlock} {
    ${tw`
      font-extrabold text-3xs laptop:text-3xs tracking-[0.5vh] mt-[0.5vh] laptop:tracking-[.75vh] laptop:mt-[.75vh]
    `}
  }
`;

const FloatingBlock = styled.div<{ isFirstTime: boolean }>`
  ${tw`
      absolute w-full 
    `};

  z-index: 10;
  background-color: black;
  transition: ${props => (props.isFirstTime ? "none" : `all ${animationDuration * 2}s ease-in`)};
`;

const Nav = styled.nav`
  ${tw`
    relative w-2 h-full ml-auto
    flex flex-row items-center justify-between
    border-primaryColor
    select-none
  `}
  writing-mode: vertical-lr;

  > ${VL} {
    &:first-child {
      ${tw`
      h-16
      border-t-2 w-0
    `}
    }

    &:last-child {
      ${tw`
    `}
      flex: 1 1 auto;
    }
  }

  ul {
    ${tw`
      relative
      w-2
      flex flex-row items-start justify-start
    `}

    > ${FloatingBlock}:first-child {
      z-index: 30;
      background-color: #e1226e;
      mix-blend-mode: difference;
    }

    > li {
      @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        > *:not(:last-child) {
          ${tw`mb-0.5`}
        }
      }

      ${tw`
        z-20
        flex flex-row items-center justify-center gap-x-0.5
      `}

      > ${VL} {
        ${tw`
          h-[1.5vh]
        `}
      }
    }
  }
`;

type NavigatorState = {
  bodyHeight: number;
  floatingHeight: number;
  floatingPos: number;
  firstTime: boolean;
};

const Navigator = () => {
  const context = useContext(ViewContext);

  const [state, setState] = useState<NavigatorState>({
    bodyHeight: window.innerHeight || document.documentElement.clientHeight,
    floatingHeight: 0,
    floatingPos: 0,
    firstTime: true,
  });

  useEffect(() => {
    const navigatorHandler = () => {
      const currentElement = document.getElementById(`nav_${context.currentView}`);
      const nextElement = document.getElementById(`nav_${context.nextView}`);
      setState(prevState => ({ ...prevState, ...{ bodyHeight: window.innerHeight || document.documentElement.clientHeight } }));

      if (currentElement !== null) {
        const { parentElement } = currentElement;
        if (parentElement !== null) {
          if (state.firstTime) {
            setState(prevState => ({
              ...prevState,
              ...{
                floatingHeight: currentElement.getBoundingClientRect().height,
                floatingPos: currentElement.getBoundingClientRect().top - parentElement.parentElement!.getBoundingClientRect().top,
              },
            }));
          }
          if (nextElement !== null) {
            setState(prevState => ({
              ...prevState,
              ...{
                floatingHeight: nextElement.getBoundingClientRect().height,
                floatingPos: nextElement.getBoundingClientRect().top - parentElement.parentElement!.getBoundingClientRect().top,
              },
            }));
          }
        }
      }
    };
    window.addEventListener("resize", navigatorHandler);
    navigatorHandler();
    return () => window.removeEventListener("resize", navigatorHandler);
  }, [context.currentView, context.nextView, state.firstTime]);

  useEffect(() => {
    if (state.firstTime && state.floatingHeight !== 0 && state.floatingPos !== 0) {
      setState(prevState => ({ ...prevState, ...{ firstTime: false } }));
    }
  }, [state.firstTime, state.floatingHeight, state.floatingPos]);

  return (
    <Nav style={{ height: state.bodyHeight }}>
      <VL />
      <ul>
        <FloatingBlock style={{ height: state.floatingHeight, top: `${state.floatingPos}px` }} isFirstTime={state.firstTime} />
        <FloatingBlock style={{ height: state.floatingHeight, top: `${state.floatingPos}px` }} isFirstTime={state.firstTime} />
        {menuItems.map(item => {
          return (
            <li key={`nav_${item.id}`}>
              <VL />
              <NavButton
                id={`nav_${MenuItems[item.name]}`}
                activeView={context.currentView === MenuItems[item.name]}
                onClick={() => context.setNextView(MenuItems[item.name])}
                isMenuOpen={context.isMenuOpen}
              >
                <TextBlock>{item.name.toUpperCase()}</TextBlock>
              </NavButton>
              <VL />
            </li>
          );
        })}
      </ul>
      <VL />
    </Nav>
  );
};
export default React.memo(Navigator);
